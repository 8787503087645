import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { Power2 } from "gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { cloneDeep } from "lodash";

import Avatar from "@/components/Avatar";
import AvatarName from "@/components/AvatarName";
import Header from "@/components/Header";
import Button from "@/components/interactives/Button";

import { countries } from "@/data/flags";

import { sfx } from "@/helpers/audio";
import gsap from "@/helpers/gsap";
import isApp from "@/helpers/isApp";
import onOrientationChange from "@/helpers/onOrientationChange";
import onWindowResize from "@/helpers/onWindowResize";
import { getConnectedPlayers } from "@/helpers/player";
import { formatPoints } from "@/helpers/stringHelper";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useForwardRef from "@/hooks/useForwardRef";

import HostIcon from "@/images/icons/icon-label-host.svg";
import MedalBronzeIcon from "@/images/icons/icon-medal-bronze-multicolor.svg";
import MedalGoldIcon from "@/images/icons/icon-medal-gold-multicolor.svg";
import MedalSilverIcon from "@/images/icons/icon-medal-silver-multicolor.svg";

import { SLIDE_TYPE_INFO_SLIDE } from "@/app-constants.mjs";
import { ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE } from "@/colors";
import { LEADERBOARD_IDLE } from "@/colors";
import {
	PLAYER_NAME_TTS_TRANSFORM,
	PLAYING_HOST_CONNECTION_ID,
	PLAY_STATUS_RATE_DONE,
	PLAY_STATUS_SHOW_RATE,
	PLAY_STATUS_SHOW_WINNER,
} from "@/constants";

import useConfetti from "../../../hooks/useConfetti";
import TextToSpeech from "../TextToSpeech";

const AUTO_SCROLL_NONE = 0;
const AUTO_SCROLL_STARTED = 1;
const AUTO_SCROLL_COMPLETE = 2;

const SCALE_WIDTH = 1920;
const SCALE_HEIGHT = 1080;

const LEADERBOARD_ITEM_HEIGHT = 68;
const LEADERBOARD_ITEM_MARGIN_BOTTOM = 26;
const LEADERBOARD_AVATAR_SIZE = 80;
const LEADERBOARD_AVATAR_STYLE = { top: `${LEADERBOARD_ITEM_HEIGHT - LEADERBOARD_AVATAR_SIZE}px` };
const LEADERBOARD_ITEM_HEIGHT_STYLE = { height: `${LEADERBOARD_ITEM_HEIGHT}px` };
const LEADERBOARD_ITEM_PLAYER_NAME_STYLE = { width: "calc(100% - 1rem)" };

const LEADERBOARD_BIG_STYLE_0 = {
	left: "50%",
	width: "calc(var(--scale) * 476px)",
};

const LEADERBOARD_BIG_STYLE_1 = {
	left: `calc(50% - (var(--scale) * ${380 + 220}px))`,
	width: "calc(var(--scale) * 380px)",
};

const LEADERBOARD_BIG_STYLE_2 = {
	left: `calc(50% + (var(--scale) * ${380 + 220}px))`,
	width: "calc(var(--scale) * 380px)",
};

const LEADERBOARD_ITEM_BACKGROUND_STYLE = {
	background: LEADERBOARD_IDLE,
	border: "0.2rem solid #000",
};

function WinnerItemCountry({ country }) {
	if (!country || country.length !== 2) {
		country = null;
	}

	if (country) {
		country = country.toLowerCase();
		if (!countries.includes(country)) {
			country = null;
		}
	}

	return (
		<img
			src={`/images/flags/${country || "unknown"}.svg`}
			alt=""
			className="md:ml-0 md:mr-2 md:w-10 md:h-10 w-6 h-6 ml-1 mr-0"
		/>
	);
}

const LeaderboardItem = forwardRef(function LeaderboardItem(
	{ rank = 0, player, hidePlayerCountry = false },
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);

	const nameContainerElementRef = useRef(null);
	const pointsContainerElementRef = useRef(null);
	const avatarContainerElementRef = useRef(null);

	useImperativeHandle(ref, () => ({
		get nameContainerElement() {
			return nameContainerElementRef.current;
		},
		get pointsContainerElement() {
			return pointsContainerElementRef.current;
		},
		get avatarContainerElement() {
			return avatarContainerElementRef.current;
		},
	}));

	const isHost = player.connectionId === PLAYING_HOST_CONNECTION_ID;

	return (
		<div
			className={tailwindCascade(
				"flex",
				"sm:w-320",
				"md:w-220",
				"sm:px-0",
				"relative",
				"flex-row",
				"justify-center",
				"w-full",
				"px-4",
				"py-0",
				"text-3xl",
				"font-black",
				"text-white",
				"h-12",
				"sm:h-90px",
				"sm:px-0",
				"mx-auto"
			)}
		>
			<div
				className="sm:h-70px sm:px-8 h-9 sm:space-x-2 -w-full-10 sm:w-full relative flex flex-row items-center justify-start px-2 rounded-full"
				style={LEADERBOARD_ITEM_BACKGROUND_STYLE}
			>
				<div className="sm:w-12 flex flex-col items-end justify-center flex-grow-0 flex-shrink-0 w-8 h-full px-2">
					<Header className="whitespace-nowrap sm:text-3xl text-base font-black text-white">
						{rank || ""}
					</Header>
				</div>
				<div
					ref={avatarContainerElementRef}
					className="sm:w-20 relative flex flex-grow-0 flex-shrink-0 w-10 h-full px-2"
				>
					<Avatar
						className="top-1/2 md:w-20 md:h-20 absolute left-0 w-10 h-10 transform -translate-y-1/2"
						playerAvatar={player.avatar}
						playerName={player.name}
						playerPoints={player.previousPoints}
						playerConnectionId={player.connectionId}
						showShuffle={false}
						showName={false}
						showPoints={false}
						disableBackground={false}
						width={LEADERBOARD_AVATAR_SIZE * 2}
						height={LEADERBOARD_AVATAR_SIZE * 2}
						hostLabel={false}
						hostBorder={true}
					/>
				</div>
				<div
					ref={nameContainerElementRef}
					className="flex flex-row items-center justify-start flex-1 w-full h-full truncate"
				>
					{!hidePlayerCountry && <WinnerItemCountry country={player.country} />}
					<Header className="sm:text-3xl px-2 text-base font-black leading-none truncate">
						{player.name}
					</Header>
					{isHost && <HostIcon className="md:w-24 w-16" />}
				</div>
				<div
					ref={pointsContainerElementRef}
					className="flex-grow-1 flex flex-col items-end justify-center h-full truncate"
				>
					<Header className="sm:text-3xl px-2 text-base font-black truncate">
						{formatPoints(player.points) || 0}
					</Header>
				</div>
			</div>
		</div>
	);
});

const BIG_ITEMS_CONTAINER_BASE_HEIGHT = 752;
const LEADERBOARD_RANK_STYLE = { left: "15%", top: "84%", width: "33%", paddingBottom: "33%" };
const TEXT_ANCHOR_MIDDLE_STYLE = { textAnchor: "middle" };

const LeaderboardItemBig = forwardRef(function LeaderboardItemBig({ player, rank = 0, style }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	const avatarContainerElementRef = useRef(null);
	const titleContainerElementRef = useRef(null);

	useImperativeHandle(ref, () => ({
		get avatarContainerElement() {
			return avatarContainerElementRef.current;
		},
		get titleContainerElement() {
			return titleContainerElementRef.current;
		},
	}));

	const avatarSize = 290 * 2;

	return (
		<div className="top-1/2 absolute transform -translate-x-1/2 -translate-y-1/2" style={style}>
			<div className="relative w-full" style={{ paddingBottom: "100%" }}>
				<div ref={avatarContainerElementRef} className="absolute inset-0 opacity-0">
					<Avatar
						className="w-full h-full"
						width={avatarSize * 2}
						height={avatarSize * 2}
						playerAvatar={player.avatar}
						playerName={player.name}
						playerPoints={player.previousPoints}
						playerConnectionId={player.connectionId}
						showShuffle={false}
						showName={false}
						showPoints={false}
					/>
					<div ref={titleContainerElementRef} className="absolute inset-0 opacity-0">
						<div className="left-1/2 top-1/2 absolute w-[122.5%] pb-[122.5%] transform -translate-x-1/2 -translate-y-1/2">
							<AvatarName className="absolute top-0 left-0 w-full h-full">{player?.name}</AvatarName>
						</div>
						<div className="left-1/2 absolute bottom-0 transform -translate-x-1/2 translate-y-full">
							<Header
								className="whitespace-nowrap text-center"
								style={{ fontSize: "calc(60px * var(--scale))" }}
							>
								{formatPoints(player.points || 0)}
							</Header>
						</div>
					</div>
					<div
						className="absolute transform -translate-x-1/2 -translate-y-1/2"
						style={LEADERBOARD_RANK_STYLE}
					>
						<div className="absolute w-full h-full">
							{rank === 1 && <MedalGoldIcon className="relative w-full h-full" />}
							{rank === 2 && <MedalSilverIcon className="relative w-full h-full" />}
							{rank === 3 && <MedalBronzeIcon className="relative w-full h-full" />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

function setInitialAvatar(element) {
	if (element) {
		gsap.set(element, { scale: 0.25, opacity: 0.0, display: "flex" });
	}
}

function setFinalAvatar(element) {
	if (element) {
		gsap.set(element, { scale: 1.0, opacity: 1.0, display: "flex" });
	}
}

function setInitialTitle(element) {
	if (element) {
		gsap.set(element, { yPercent: 10, opacity: 0.0 });
	}
}

function setFinalTitle(element) {
	if (element) {
		gsap.set(element, { yPercent: 0, opacity: 1.0 });
	}
}

function setInitialTop3(obj) {
	if (obj?.avatarContainerElement) {
		gsap.set(obj?.avatarContainerElement, { opacity: 0.0 });
	}
	if (obj?.nameContainerElement) {
		gsap.set(obj?.nameContainerElement, { opacity: 0.0 });
	}
	if (obj?.pointsContainerElement) {
		gsap.set(obj?.pointsContainerElement, { opacity: 0.0 });
	}
}

function setFinalTop3(obj) {
	if (obj?.avatarContainerElement) {
		gsap.set(obj?.avatarContainerElement, { opacity: 1.0 });
	}
	if (obj?.nameContainerElement) {
		gsap.set(obj?.nameContainerElement, { opacity: 1.0 });
	}
	if (obj?.pointsContainerElement) {
		gsap.set(obj?.pointsContainerElement, { opacity: 1.0 });
	}
}

function addTop3InTween(timeline, obj) {
	if (obj?.avatarContainerElement) {
		timeline.to(obj?.avatarContainerElement, { opacity: 1.0, ease: "power2.out" }, "<");
	}
	if (obj?.nameContainerElement) {
		timeline.to(obj?.nameContainerElement, { opacity: 1.0, ease: "power2.out" }, "<");
	}
	if (obj?.pointsContainerElement) {
		timeline.to(obj?.pointsContainerElement, { opacity: 1.0, ease: "power2.out" }, "<");
	}
}

function addAvatarTimelineInTween(timeline, element, startDelay = 0, onStart = null, onComplete = null) {
	if (element) {
		timeline.to(
			element,
			{
				opacity: 1.0,
				duration: 0.125,
				ease: "none",
				onStart: () => {
					if (onStart) {
						onStart();
					}
				},
				onComplete: () => {
					if (onComplete) {
						onComplete();
					}
				},
			},
			`+=${startDelay}`
		);
		timeline.to(element, { scale: 1.0, duration: 1, ease: "bounce.out" }, "<");
	}
}

function addTitleTimelineInTween(timeline, element, startDelay = 0, onStart = null, onComplete = null) {
	if (element) {
		timeline.to(
			element,
			{
				opacity: 1.0,
				duration: 0.5,
				ease: "none",
				onStart: () => {
					if (onStart) {
						onStart();
					}
				},
				onComplete: () => {
					if (onComplete) {
						onComplete();
					}
				},
			},
			`+=${startDelay}`
		);
		timeline.to(element, { yPercent: 0, duration: 0.5, ease: "power2.out" }, "<");
	}
}

function addVoiceTimelineInTween(timeline, element, startDelay = 0, onStart = null, onComplete = null) {
	if (element) {
		timeline.to(
			element,
			{
				duration: 3.0,
				onStart: () => {
					if (onStart) {
						onStart();
					}
				},
				onComplete: () => {
					if (onComplete) {
						onComplete();
					}
				},
			},
			`+=${startDelay}`
		);
	}
}

export function WinnerView({
	className,
	containerClassName,
	onDone,
	onRate,
	onReport,
	leaderboard: lockedPlayers = [],
	sendPlayerPointsAndPos,
	isHost = false,
	reducedMotion = false,
	statusWithProgress,
	hidePlayerCountry = false,
	report = null,
	...props
}) {
	const sendPlayerPointsAndPosRef = useRef(sendPlayerPointsAndPos);
	useEffect(() => void (sendPlayerPointsAndPosRef.current = sendPlayerPointsAndPos), [sendPlayerPointsAndPos]);

	const onRateRef = useRef(onRate);
	useEffect(() => void (onRateRef.current = onRate), [onRate]);

	const wrapperRef = useRef(null);

	const [play1stPlaceName, setPlay1stPlaceName] = useState(false);
	const [play2ndPlaceName, setPlay2ndPlaceName] = useState(false);
	const [play3rdPlaceName, setPlay3rdPlaceName] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			if (scrollCompleteRef.current && autoScrollRef.current === AUTO_SCROLL_COMPLETE) {
				setAutoScroll(AUTO_SCROLL_COMPLETE);
			}
		};
		const onClick = () => {
			if (scrollCompleteRef.current) {
				setAutoScroll(AUTO_SCROLL_COMPLETE);
			}
		};

		const wrapper = wrapperRef.current;
		if (wrapper) {
			wrapper.addEventListener("scroll", onScroll);
		}
		window.addEventListener("scroll", onScroll);
		window.addEventListener("click", onClick);
		return () => {
			window.removeEventListener("click", onClick);
			window.removeEventListener("scroll", onScroll);
			if (wrapper) {
				wrapper.removeEventListener("scroll", onScroll);
			}
		};
	}, []);

	const containerRef = useRef(null);
	const itemsRef = useRef(null);
	const leaderboardItemScaleRef = useRef(null);
	const leaderboardItemRef = useRef(null);
	const itemContainerRef = useRef(null);
	const bigItemsRef = useRef(null);

	const inTimelineRef = useRef(null);

	const firstPlaceRef = useRef(null);
	const secondPlaceRef = useRef(null);
	const thirdPlaceRef = useRef(null);

	const firstPlaceLeadeboardRef = useRef(null);
	const secondPlaceLeadeboardRef = useRef(null);
	const thirdPlaceLeadeboardRef = useRef(null);

	const [scrollComplete, setScrollComplete] = useState(false);
	const scrollCompleteRef = useRef(scrollComplete);
	useEffect(() => void (scrollCompleteRef.current = scrollComplete), [scrollComplete]);

	const [autoScroll, setAutoScroll] = useState(AUTO_SCROLL_NONE);
	const autoScrollRef = useRef(autoScroll);
	useEffect(() => void (autoScrollRef.current = autoScroll), [autoScroll]);

	const reducedMotionRef = useRef(reducedMotion);
	useEffect(() => void (reducedMotionRef.current = reducedMotion), [reducedMotion]);

	const showConfetti = useConfetti({
		particleCount: 200,
		angle: 90,
		spread: 360,
		startVelocity: 89,
		decay: 0.6,
		gravity: 0.6,
		drift: 0,
		ticks: 200,
		colors: [ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE],
		shapes: ["square", "square", "square", "circle"],
		scalar: 1.9,
		zIndex: 59,
	});
	const showConfettiRef = useRef(showConfetti);
	useEffect(() => void (showConfettiRef.current = showConfetti), [showConfetti]);

	const resize = useCallback(() => {
		if (bigItemsRef.current) {
			const height = bigItemsRef.current.offsetHeight;
			const scale = height / BIG_ITEMS_CONTAINER_BASE_HEIGHT;
			bigItemsRef.current.style.setProperty("--scale", scale);
		}

		if (leaderboardItemScaleRef.current) {
			const { width } = wrapperRef.current.getBoundingClientRect();
			const scale = Math.min(width / SCALE_WIDTH, 1);
			leaderboardItemScaleRef.current.style.setProperty("--scale", scale);
		}
	}, []);
	const resizeRef = useRef(resize);
	useEffect(() => void (resizeRef.current = resize), [resize]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_SHOW_WINNER) {
			const dispose = () => {
				if (inTimelineRef.current) {
					inTimelineRef.current.kill();
					inTimelineRef.current = null;
				}
			};

			const timeline = (inTimelineRef.current = gsap.timeline({
				onComplete: () => {
					dispose();

					if (resizeRef.current) {
						resizeRef.current();
					}
				},
			}));

			setInitialTop3(firstPlaceLeadeboardRef?.current);
			setInitialTop3(secondPlaceLeadeboardRef?.current);
			setInitialTop3(thirdPlaceLeadeboardRef?.current);

			setInitialAvatar(firstPlaceRef?.current?.avatarContainerElement);
			setInitialTitle(firstPlaceRef?.current?.titleContainerElement);

			setInitialAvatar(secondPlaceRef?.current?.avatarContainerElement);
			setInitialTitle(secondPlaceRef?.current?.titleContainerElement);

			setInitialAvatar(thirdPlaceRef?.current?.avatarContainerElement);
			setInitialTitle(thirdPlaceRef?.current?.titleContainerElement);

			if (bigItemsRef?.current) {
				gsap.set(bigItemsRef?.current, { opacity: 0 });
			}
			if (itemsRef?.current) {
				gsap.set(itemsRef?.current, { opacity: 0 });
			}

			if (!scrollCompleteRef.current && itemsRef.current && lockedPlayers.length > 3) {
				if (itemsRef?.current) {
					gsap.set(itemsRef?.current, { y: 0, opacity: 1, yPercent: -100 });
				}
				if (bigItemsRef?.current) {
					gsap.set(bigItemsRef?.current, { opacity: 1 });
				}
				if (itemsRef?.current) {
					timeline.to(
						itemsRef?.current,
						{
							yPercent: 0,
							duration: 5,
							ease: Power2.easeInOut,
							onStart: () => {
								lockedPlayers.forEach(
									(player) =>
										void (
											sendPlayerPointsAndPosRef.current &&
											sendPlayerPointsAndPosRef.current(player, null)
										)
								);
								if (resizeRef.current) {
									resizeRef.current();
								}
							},
							onComplete: () => {
								lockedPlayers.forEach((player, i) => {
									sendPlayerPointsAndPosRef.current &&
										sendPlayerPointsAndPosRef.current(player, i >= 3 ? i + 1 : null);
								});
								if (resizeRef.current) {
									resizeRef.current();
								}
							},
						},
						"<"
					);
					timeline.add(() => void sfx.play("lbPlayerUp"), "<2.5");
				} else {
					lockedPlayers.forEach((player, i) => {
						sendPlayerPointsAndPosRef.current &&
							sendPlayerPointsAndPosRef.current(player, i >= 3 ? i + 1 : null);
					});
					if (resizeRef.current) {
						resizeRef.current();
					}
				}
			} else {
				if (itemsRef?.current) {
					gsap.set(itemsRef?.current, { y: 0, opacity: 1, yPercent: 0 });
				}
				if (bigItemsRef?.current) {
					gsap.set(bigItemsRef?.current, { opacity: 1 });
				}
				if (leaderboardItemRef?.current) {
					gsap.set(leaderboardItemRef?.current, { y: 0 });
				}
				if (resizeRef.current) {
					resizeRef.current();
				}
			}

			if (lockedPlayers.length > 2) {
				addVoiceTimelineInTween(
					timeline,
					thirdPlaceRef?.current?.titleContainerElement,
					0,
					() => void setPlay3rdPlaceName(true)
				);
				addAvatarTimelineInTween(timeline, thirdPlaceRef?.current?.avatarContainerElement);
				addTitleTimelineInTween(timeline, thirdPlaceRef?.current?.titleContainerElement, 0, () => {
					sfx.play("top3_no3");

					if (!reducedMotionRef.current && showConfettiRef.current) {
						showConfettiRef.current(bigItemsRef.current, thirdPlaceRef?.current?.avatarContainerElement);
					}

					if (lockedPlayers.length >= 3) {
						sendPlayerPointsAndPosRef.current && sendPlayerPointsAndPosRef.current(lockedPlayers[2], 3);
					}

					if (resizeRef.current) {
						resizeRef.current();
					}
				});
				addTop3InTween(timeline, thirdPlaceLeadeboardRef?.current);
			}

			if (lockedPlayers.length > 1) {
				addVoiceTimelineInTween(
					timeline,
					secondPlaceRef?.current?.titleContainerElement,
					0,
					() => void setPlay2ndPlaceName(true)
				);
				addAvatarTimelineInTween(timeline, secondPlaceRef?.current?.avatarContainerElement);
				addTitleTimelineInTween(timeline, secondPlaceRef?.current?.titleContainerElement, 0, () => {
					sfx.play("top3_no2");

					if (!reducedMotionRef.current && showConfettiRef.current) {
						showConfettiRef.current(bigItemsRef.current, secondPlaceRef?.current?.avatarContainerElement);
					}

					if (lockedPlayers.length >= 2) {
						sendPlayerPointsAndPosRef.current && sendPlayerPointsAndPosRef.current(lockedPlayers[1], 2);
					}

					if (resizeRef.current) {
						resizeRef.current();
					}
				});
				addTop3InTween(timeline, secondPlaceLeadeboardRef?.current);
			}

			if (lockedPlayers.length > 0) {
				addVoiceTimelineInTween(
					timeline,
					firstPlaceRef?.current?.titleContainerElement,
					0,
					() => void setPlay1stPlaceName(true)
				);

				addAvatarTimelineInTween(timeline, firstPlaceRef?.current?.avatarContainerElement);
				addTitleTimelineInTween(
					timeline,
					firstPlaceRef?.current?.titleContainerElement,
					0,
					() => {
						sfx.play("top3_no1");

						if (!reducedMotionRef.current && showConfettiRef.current) {
							showConfettiRef.current(
								bigItemsRef.current,
								firstPlaceRef?.current?.avatarContainerElement
							);
						}

						if (lockedPlayers.length >= 1) {
							sendPlayerPointsAndPosRef.current && sendPlayerPointsAndPosRef.current(lockedPlayers[0], 1);
						}

						if (resizeRef.current) {
							resizeRef.current();
						}
					},
					() => void sfx.play("applause")
				);
				addTop3InTween(timeline, firstPlaceLeadeboardRef?.current);

				if (leaderboardItemRef?.current) {
					timeline.to(
						leaderboardItemRef?.current,
						{
							y: 0,
							ease: "power2.out",
						},
						"<"
					);
				}
			}

			timeline.add(() => setScrollComplete(true), "<");

			return () => void dispose();
		} else {
			setFinalTop3(firstPlaceLeadeboardRef?.current);
			setFinalTop3(secondPlaceLeadeboardRef?.current);
			setFinalTop3(thirdPlaceLeadeboardRef?.current);

			setFinalAvatar(firstPlaceRef?.current?.avatarContainerElement);
			setFinalTitle(firstPlaceRef?.current?.titleContainerElement);

			setFinalAvatar(secondPlaceRef?.current?.avatarContainerElement);
			setFinalTitle(secondPlaceRef?.current?.titleContainerElement);

			setFinalAvatar(thirdPlaceRef?.current?.avatarContainerElement);
			setFinalTitle(thirdPlaceRef?.current?.titleContainerElement);

			if (itemsRef?.current) {
				gsap.set(itemsRef?.current, { y: 0, opacity: 1, yPercent: 0 });
			}
			if (bigItemsRef?.current) {
				gsap.set(bigItemsRef?.current, { opacity: 1 });
			}
			if (leaderboardItemRef?.current) {
				gsap.set(leaderboardItemRef?.current, { y: 0 });
			}

			if (resizeRef.current) {
				resizeRef.current();
			}

			setScrollComplete(true);
		}
	}, [statusWithProgress.name, lockedPlayers]);

	useEffect(() => {
		if (resizeRef.current) {
			resizeRef.current(); // Initial resize
		}

		const disposeOnWindowResize = resizeRef.current ? onWindowResize(resizeRef.current) : null;
		const disposeOnOrientationChange = resizeRef.current ? onOrientationChange(resizeRef.current) : null;
		return () => {
			if (disposeOnWindowResize) {
				disposeOnWindowResize();
			}
			if (disposeOnOrientationChange) {
				disposeOnOrientationChange();
			}
		};
	}, []);

	const scrollToTweenRef = useRef(null);

	useEffect(() => {
		if (scrollComplete && autoScrollRef.current === AUTO_SCROLL_NONE && wrapperRef.current) {
			setAutoScroll(AUTO_SCROLL_STARTED);

			if (onRateRef.current) {
				onRateRef.current();
			}

			if (scrollToTweenRef.current) {
				scrollToTweenRef.current.kill();
				scrollToTweenRef.current = null;
			}

			gsap.registerPlugin(ScrollToPlugin);
			const tween = (scrollToTweenRef.current = gsap.to(wrapperRef.current, {
				delay: 5,
				duration: wrapperRef.current.scrollHeight / 50,
				scrollTo: {
					y: wrapperRef.current.scrollHeight,
					autoKill: true,
					onAutoKill: () => {
						setAutoScroll(AUTO_SCROLL_COMPLETE);
						scrollToTweenRef.current = null;
					},
					onComplete: () => {
						setAutoScroll(AUTO_SCROLL_COMPLETE);
						scrollToTweenRef.current = null;
					},
				},
			}));

			return () => {
				tween.kill();
				scrollToTweenRef.current = null;
			};
		}
	}, [scrollComplete]);

	useEffect(() => {
		if (autoScroll === AUTO_SCROLL_COMPLETE && scrollToTweenRef.current) {
			scrollToTweenRef.current.kill();
			scrollToTweenRef.current = null;
		}
	}, [autoScroll]);

	const showButtons = useMemo(
		() => [PLAY_STATUS_SHOW_RATE, PLAY_STATUS_RATE_DONE].includes(statusWithProgress.name),
		[statusWithProgress.name]
	);

	return (
		<>
			<div ref={wrapperRef} className="flex flex-col flex-1 w-full h-full overflow-x-hidden">
				<div className="flex flex-row flex-1 w-screen">
					<div
						ref={containerRef}
						className={tailwindCascade("relative w-full overflow-hidden pt-12", containerClassName)}
					>
						{isHost && showButtons && (
							<div className="z-1 fixed right-4 top-12 md:top-18">
								<div className="sm:flex-row flex flex-col items-center justify-center w-full gap-4">
									{!isApp && report && (
										<Button
											color="white"
											className="text-black lg:min-w-[11.5rem] min-w-[9.5rem] px-4 py-2 lg:px-8 lg:py-3"
											border={true}
											onClick={onReport}
										>
											{trans("View report")}
										</Button>
									)}
									<Button
										color="green-light"
										className="text-white lg:min-w-[11.5rem] min-w-[9.5rem] hidden md:block px-4 py-2 lg:px-8 lg:py-3"
										border={true}
										onClick={onDone}
									>
										{trans("Done")}
									</Button>
								</div>
							</div>
						)}
						<div
							ref={itemsRef}
							className={tailwindCascade("w-full opacity-0 transform", {
								relative: lockedPlayers.length <= 1 || scrollComplete,
								absolute: lockedPlayers.length > 1 && !scrollComplete,
							})}
						>
							<div ref={itemContainerRef} className="md:container md:mx-auto w-full">
								<div
									ref={bigItemsRef}
									className="relative w-full opacity-0"
									style={{
										paddingBottom: `${(BIG_ITEMS_CONTAINER_BASE_HEIGHT / SCALE_WIDTH) * 100}%`,
									}}
								>
									<div className="absolute top-0 left-0 w-full h-full">
										{lockedPlayers.length > 0 && (
											<LeaderboardItemBig
												ref={firstPlaceRef}
												rank={1}
												player={lockedPlayers[0]}
												style={LEADERBOARD_BIG_STYLE_0}
											/>
										)}

										{lockedPlayers.length > 1 && (
											<LeaderboardItemBig
												ref={secondPlaceRef}
												rank={2}
												player={lockedPlayers[1]}
												style={LEADERBOARD_BIG_STYLE_1}
											/>
										)}

										{lockedPlayers.length > 2 && (
											<LeaderboardItemBig
												ref={thirdPlaceRef}
												rank={3}
												player={lockedPlayers[2]}
												style={LEADERBOARD_BIG_STYLE_2}
											/>
										)}
									</div>
								</div>
							</div>

							<div ref={leaderboardItemScaleRef} className="md:container md:mx-auto w-full">
								<div className="flex flex-col items-center w-full">
									{lockedPlayers.length > 1 && (
										<div className="sm:transform sm:scale-container sm:origin-top sm:w-320 md:w-220 flex flex-col items-center w-full">
											<div
												ref={leaderboardItemRef}
												className="md:-translate-y-16 relative w-full transform -translate-y-4"
											>
												{lockedPlayers.length > 0 && (
													<LeaderboardItem
														ref={firstPlaceLeadeboardRef}
														player={lockedPlayers[0]}
														rank={1}
														hidePlayerCountry={hidePlayerCountry}
													/>
												)}

												{lockedPlayers.length > 1 && (
													<LeaderboardItem
														ref={secondPlaceLeadeboardRef}
														player={lockedPlayers[1]}
														rank={2}
														hidePlayerCountry={hidePlayerCountry}
													/>
												)}

												{lockedPlayers.length > 2 && (
													<LeaderboardItem
														ref={thirdPlaceLeadeboardRef}
														player={lockedPlayers[2]}
														rank={3}
														hidePlayerCountry={hidePlayerCountry}
													/>
												)}

												{lockedPlayers.map(
													(player, index) =>
														index > 2 && (
															<LeaderboardItem
																key={index}
																player={player}
																rank={index + 1}
																hidePlayerCountry={hidePlayerCountry}
															/>
														)
												)}
											</div>
										</div>
									)}
								</div>
							</div>

							<div
								className={`w-full ${lockedPlayers.length <= 1 || scrollComplete ? "h-8" : "h-screen"}`}
							/>
						</div>
					</div>
				</div>
				{lockedPlayers.length >= 1 && (
					<TextToSpeech
						voice="Samantha"
						text={["And the winner is... ", PLAYER_NAME_TTS_TRANSFORM(lockedPlayers[0].name)]}
						play={play1stPlaceName}
						isHost={true}
					/>
				)}
				{lockedPlayers.length >= 2 && (
					<TextToSpeech
						voice="Samantha"
						text={["Second place...", PLAYER_NAME_TTS_TRANSFORM(lockedPlayers[1].name)]}
						play={play2ndPlaceName}
						isHost={true}
					/>
				)}
				{lockedPlayers.length >= 3 && (
					<TextToSpeech
						voice="Samantha"
						text={["Third place...", PLAYER_NAME_TTS_TRANSFORM(lockedPlayers[2].name)]}
						play={play3rdPlaceName}
						isHost={true}
					/>
				)}
			</div>
		</>
	);
}

export function getFinalLeaderboard(players, teams, teamMode) {
	if (teamMode) {
		const teamsArray = cloneDeep([...teams.values()]);
		teamsArray.sort((a, b) => b.points - a.points);
		return teamsArray;
	} else {
		const lockedPlayersMap = new Map();
		const connectedPlayers = getConnectedPlayers([...players.values()]).sort((a, b) => b.points - a.points);
		for (let i = 0; i < connectedPlayers.length; i++) {
			const player = connectedPlayers[i];
			const connectionId = (player && player.connectionId) || null;
			if (connectionId) {
				lockedPlayersMap.set(connectionId, {
					connectionId,
					name: player.name,
					points: player.points,
					previousPoints: player.previousPoints,
					avatar: player.avatar,
					country: player.country,
				});
			}
		}

		const lockedPlayers = Array.from(lockedPlayersMap.values());
		lockedPlayers.sort((a, b) => b.points - a.points);
		return lockedPlayers;
	}
}

export function getFinalReport(players, slides) {
	if (!slides || !players) {
		return [];
	}

	const numQuestionSlides = slides.filter((slide) => slide.type !== SLIDE_TYPE_INFO_SLIDE).length;
	if (!numQuestionSlides) {
		return [];
	}

	const report = [...players.values()].map((player) => ({
		name: player.name,
		avatar: player.teamId ? null : player.avatar,
		accuracy:
			Object.values(player.history).reduce((sum, { correctness }) => sum + correctness, 0) / numQuestionSlides,
		score: Object.values(player.history).reduce((sum, { points }) => sum + points, 0),
		history: player.history,
	}));
	return report;
}
